import React from 'react';
import Layout from 'src/components/Layout';
import { Section } from '../Section';
import sizes from 'src/style/sizes';
import { TopbarStaticSpace } from '../Topbar';
import { FAQWhiteBG } from '../QuestionsAnswer/index';
import MainVideoTestimonial from '../Bootcamp/Testimonials/MainVideoTestimonial';
import { mediaQueries } from 'src/style/breakpoints';

const Landing = React.memo(({ location, content, pageName, metaTitle, children }) => {
  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C|B2B"
      pageName={pageName}
      pageType="page"
      metaTitle={metaTitle}
    >
      <Section theme="primary" css={{ height: sizes.purpleBgHeightDesktop }} />
      <TopbarStaticSpace />
      <Section theme="secondary">
        <FAQWhiteBG marginTop={sizes.marginTopContainer} theme="light">
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .landing h1 {
              margin: 50px 0;
            } 
            .landing h2, h3 {
              margin: 40px 0 30px 0;
            }
            .landing h1, h2, h3 {
              color: #190D33;
              font-weight: 700;
              font-style: normal;
            } 
            .landing h2 {
              display: flex;
              flex-direction: row;
            }  
            .landing h2::before {
              margin-top: 12px;
              margin-right: 12px;
              width:26px;
              height:6px;
              border-radius:50px;
              background-color:#FCC01A;
              display: block;
              content: "";
            } 
            .landing p {
              margin-bottom: 10px;
              color: #190D33;
            }
            .landing p, .landing li {
              font-size: 19px;
              line-height: 28px;
              font-style: normal;
              font-weight: 500;
              color: #190D33;
            }
            .landing ol, ul, li {
              list-style-position:inside;
            }
            .landing ol, ul {
              margin: 10px 0 10px 0;
            }
            .landing p a:link,
            .landing p a:visited,
            .landing li a:link,
            .landing li a:visited {
              color: #5C48D3;
            }
            .landing a:hover {
              text-decoration: none;
            }
            .landing .alert {
              border-radius: 5px;
              padding: 30px;
              margin: 20px 0;
            }
            .landing .alert-info {
              background-color: rgb(229, 226, 255);
              color: rgb(93, 71, 210);
              border: 1px solid rgb(220, 215, 255);
            }
          `,
            }}
          ></style>
          {content.map((elem) => {
            if (elem.type === 'md') {
              return (
                <div
                  key={elem.content}
                  dangerouslySetInnerHTML={{ __html: elem.content }}
                  style={{
                    color: '#546476',
                    fontFamily: 'HK Compakt',
                  }}
                  className="landing"
                ></div>
              );
            } else if (elem.type === 'youtube') {
              return (
                <div
                  key={elem.link}
                  css={{
                    width: '100%',
                    margin: 'auto',
                    marginBottom: '50px',
                    [mediaQueries.desktopUp]: {
                      width: '50%',
                    },
                  }}
                >
                  <MainVideoTestimonial mainThumbnail={elem.mainThumbnail} link={elem.link} />
                </div>
              );
            } else {
              return null;
            }
          })}
          {children}
        </FAQWhiteBG>
      </Section>
    </Layout>
  );
});

export default Landing;
